import classes from "./Car.module.scss";
import { CarPropsType } from "../Model";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGasPump } from "@fortawesome/free-solid-svg-icons/faGasPump";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons/faTachometerAlt";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";

function trackCarView(source: string, car: string) {
  window.gtag("event", `car_${source}_view`, {
    value: car,
    event_category: "car_iew",
  });
}

export default function Car(props: CarPropsType) {
  return (
    <div className={classes.carGrid}>
      <div className="row">
        <div className="col-lg-4 col-md-12">
          <a
            href={props.href}
            rel="noreferrer"
            target="_blank"
            style={{
              paddingTop: "75%",
              display: "block",
              position: "relative",
            }}
            onClick={() => {
              trackCarView("image", `${props.name} ${props.year}`);
            }}
          >
            <img
              src={props.picture}
              alt={props.name}
              style={{ position: "absolute", top: 0 }}
            />
          </a>
        </div>
        <div className="col-lg-8 col-md-12">
          <div className={classes.carTitle}>
            <a
              href={props.href}
              rel="noreferrer"
              target="_blank"
              onClick={() => {
                trackCarView("title", `${props.name} ${props.year}`);
              }}
            >
              <span className={classes.price}>
                ${new Intl.NumberFormat("ua-UA").format(props.price)}
              </span>{" "}
              {props.name} <span className={classes.year}>{props.year}</span>{" "}
            </a>
          </div>

          <div className={classes.carList}>
            <ul className="list-inline">
              <li>
                <FontAwesomeIcon icon={faGasPump} />
                {` ${props.fuelType} ${
                  props.engineVolume ? props.engineVolume : ""
                }`}
              </li>

              <li>
                <i className={classes.faGearbox}></i> {props.transmission}
              </li>
              <li>
                <FontAwesomeIcon icon={faTachometerAlt} />{" "}
                {props.mileage &&
                  new Intl.NumberFormat("ua-UA").format(
                    props.mileage * 1000
                  )}{" "}
                км
              </li>
              <li>
                <FontAwesomeIcon icon={faMapMarkerAlt} /> {props.location}
              </li>
            </ul>{" "}
          </div>

          <p className={classes.description}>{props.description}</p>
          <a
            className="button"
            href={props.href}
            rel="noreferrer"
            target="_blank"
            onClick={() => {
              trackCarView("button", `${props.name} ${props.year}`);
            }}
          >
            Детальніше
          </a>
        </div>
      </div>
    </div>
  );
}

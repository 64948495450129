import React, { ReactElement, useEffect, useState } from "react";
import Footer from "../components/footer/Footer";
import HeaderContacts from "../components/header/HeaderContacts";
import MainMenu from "../components/menu/MainMenu";

export default function Page(props: { children: ReactElement[] }) {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const listener = () => {
      setVisible(window.scrollY > window.innerHeight / 2);
    };
    window.addEventListener("scroll", listener);
    return () => window.removeEventListener("scroll", listener);
  }, []);
  return (
    <>
      <HeaderContacts />
      <MainMenu />
      {props.children}
      <Footer />
      <div
        id="backToTop"
        className={visible ? "visible" : ""}
        onClick={() => window.scrollTo({ top: 0 })}
      >
        нагору
      </div>
    </>
  );
}

import { LOCATIONS } from "../../config";
import "./Schedule.scss";
import { trackContact } from "../trackContact";

export default function Schedule() {
  return (
    <section className="white-bg page-section-ptb">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="text-center">
              <div className="row">
                {/* <div
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 100,
                    background: "black",
                    margin: "0 auto",
                    overflow: "hidden",
                  }}
                  className="mb-1"
                >
                  <img className="img-fluid" src="images/salesman.jpg" alt="" />
                </div> */}
              </div>

              <h6 className="pb-2 pt-2">
                Маєте запитання? Телефонуйте, ми з радістю дамо відповідь!
              </h6>
              <div className="row">
                {LOCATIONS.map((city) => (
                  <div className="col-md-6" key={city.location}>
                    <h6>{city.location}</h6>
                    <h2 className="text-red">
                      <a
                        href={`tel:${city.phone}`}
                        onClick={() => trackContact("phone")}
                      >
                        {city.phone}
                      </a>
                    </h2>
                    <p>
                      Щодня з {city.startingHour}:00 до {city.closingHour}:00
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <h6 className="text-center pb-2">
              Або надсилайте нам повідомлення
            </h6>

            <div className="gray-form">
              <iframe
                title="Надішліть нам запитання"
                src="https://us7.list-manage.com/contact-form?u=98344bd023be06e4fdc23f65d&form_id=0496a67ae5a75431c2688acdf707d2a6

"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import "./ServiceSteps.scss";
type ServiceStepsProps = {
  serviceName: string;
  serviceDescription: string;
  steps: {
    heading: string;
    description: string;
  }[];
};
export function ServiceSteps(props: ServiceStepsProps) {
  return (
    <>
      <div className="container mb-sm-5 mt-5">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h2>{props.serviceName}</h2>
              <span>{props.serviceDescription}</span>
              <div className="separator"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ul className="timeline list-style-none">
              {props.steps.map((step, index) => (
                <li
                  key={step.heading}
                  className={index % 2 ? "timeline-inverted" : ""}
                >
                  <div className="timeline-badge">
                    <h4>{index + 1}</h4>
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h5>{step.heading}</h5>
                    </div>
                    <div className="timeline-body">
                      <p>{step.description}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect } from "react";
import { FAQ } from "../components/faq/FAQ";
import { ServiceSteps } from "../components/services/ServiceSteps";
import { services } from "./Home";
import Page from "./Page";

export default function CarOrdering() {
  useEffect(() => {
    document.title = "Бернс Авто - Авто під замовлення";

    window.gtag("event", "page_view", {
      page_title: "Авто під замовлення",
      page_path: services.services[2].href,
    });
  }, []);
  return (
    <Page>
      <ServiceSteps
        serviceName="Авто під замовлення"
        serviceDescription="Бернс Авто знайде потрібний вам автомобіль в Україні або закородоном. Весь можна розділити на наступні кроки:"
        steps={[
          {
            heading: "Зустріч",
            description:
              "Ви приїжджаєте в наш офіс і описуєте яке авто ви хочете",
          },
          {
            heading: "Пошук та Оцінка",
            description:
              "Одразу, або на протязі дня ми надсилаємо вам перелік знайдених нами авто, їх цін, та розрахунку вартості наших послуг",
          },
          {
            heading: "Завдаток",
            description:
              "Ви залишаєте завдаток у розмірі 10% від вартості автомобіля",
          },
          {
            heading: "Перегляд та перевірка авто",
            description:
              "Наші спеціалісти вирушають на огляд автомобілів, які пройшли наш відбір згідно характеристик, стану, ціни, умов купівлі. Результати будуть вам надіслані у електронному форматі. Наш спеціст зв'яжеться з вами, щоб описати результати огляду та щоб ви підтвердили покупку. Ми будемо продовжувати переглядяти до 10 авто поки не знайдемо те яке вас задовільнить.",
          },
          {
            heading: "Купівля, транспортування та реєстрація",
            description:
              "Як тільки ви погоджуєте один із варіантів ми купуємо авто, транспортуємо його до вас та реєструємо.",
          },
          {
            heading: "Отримання",
            description:
              "Ви переглядаєте авто у нашому автосалоні та робите тест-драйв. Після цього ви сплачуєте обговорену вартість і отримуєте ваш автомобіль.",
          },
        ]}
      />
      <FAQ
        questions={[
          {
            question:
              "Чим це вигідніше ніж просто купити та привезти авто самому?",
            answer:
              "Вам не нікуди не потрібно їхати. Ви не несете ризиків пов'язаних з поїздками та траспортуванням. Наші спеціалісти знають нюанси місцевих законів та роботи автодиллерів, що дозволяє вам уникнути багатьох складнощів при покупці та транспортуванні авто. Ми переряємо автомобілі на предмет ДТП, оригінальність пробігу і технічний стан.",
          },
          {
            question: "Чому замовляти авто саме у Бернс Авто?",
            answer:
              "Ми знайдемо саме таке авто, як ви описали, без прихованих витрат та дефектів (вартісь ремонту, доставки, страхівки, розмитення, т.д.).",
          },
          {
            question: "Які автомобілі Бернс Авто може пригнати?",
            answer: "Будь-які легкові авто та мотоцикли від 2000 року випуску.",
          },
          {
            question: "Скільки коштують послуги Бернс Авто?",
            answer:
              "Ціни реєстрації, доставка, розмитнення і наші послуг залежать від конкрених запитів клієнта. Всі ціни обговорється наперед та надаються у розгорнутому вигляді для максимальної прозорості.",
          },
          {
            question:
              "Які додаткові кошти я сплачую за послугу крім ціни автомобіля?",
            answer: "Розмитення, доставку, реєстрацію, та послуги Бернс Авто.",
          },
          {
            question: "Чи можу я відмовитись від послуги?",
            answer:
              "Так, вам повертається 100% ваших коштів, якщо до того моменту ми ще не оглягули жодного автомобіля. Після перегляду першого авто, ми повертаємо кошти з вирахування частини наших послуг.",
          },
          {
            question: "Хто займається розмитенням та реєстрацією авто?",
            answer: "Бернс Авто.",
          },
          {
            question: "Як довго чекати на моє авто?",
            answer:
              "Від 2 тижнів до 1 місяця. Пошук може можу бути довшим, якщо авто екслюзивне.",
          },
          {
            question:
              "Якщо пригнане авто відрізняється по параметрах від того, про яке ми домовились?",
            answer:
              "Ви не буде забов'язані його купувати і ми повернемо вам 100% ваших коштів.",
          },
          {
            question:
              "Якщо я помічу необговорені недоліки в авто після отримання?",
            answer:
              "Ми повідомляємо вам усі недоліки при огляді авто. Якщо на момент отримання автомобіль має необговоренні видимі косметичні, або технічні недоліки - ми їх усунемо, або компенсуємо їхню вартість.",
          },
        ]}
      />
    </Page>
  );
}

export default function SignUpForm() {
  return (
    <div>
      <div id="mc_embed_signup" className="news-letter text-white">
        <form
          action="https://gmail.us7.list-manage.com/subscribe/post?u=98344bd023be06e4fdc23f65d&amp;id=342b0f95c0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate news-letter"
          target="_blank"
          noValidate
        >
          <div id="mc_embed_signup_scroll" />
          <h6 className="text-white">Підпишіться на нашу розсилку</h6>
          <p>
            Отримуйте інформацію про знижки, нові пропозицій та пуслуги.
            Надсилаємо раз в місяць, без спаму.
          </p>
          <div className="row">
            <div className="col-8 col-md-9">
              <div className="mc-field-group">
                <input
                  type="email"
                  name="EMAIL"
                  required={true}
                  placeholder="Введіть свій Email"
                  className="required email form-control placeholder"
                  id="mce-EMAIL"
                />
              </div>
            </div>
            <div className="col-4 col-md-3">
              <div className="clear">
                <input
                  type="submit"
                  defaultValue="Підписатись!"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button red"
                />
              </div>
            </div>
          </div>
          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>{" "}
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden={true}
          >
            <input
              type="text"
              name="b_98344bd023be06e4fdc23f65d_342b0f95c0"
              tabIndex={-1}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { FAQ } from "../components/faq/FAQ";
import { ServiceSteps } from "../components/services/ServiceSteps";
import { services } from "./Home";
import Page from "./Page";

export default function WeBuyYourCar() {
  useEffect(() => {
    document.title = "Бернс Авто - Автовикуп";

    window.gtag("event", "page_view", {
      page_title: "Автовикуп",
      page_path: services.services[0].href,
    });
  }, []);
  return (
    <Page>
      <ServiceSteps
        serviceName="Автовикуп"
        serviceDescription="Купимо ваше авто швидко та зручно по вигідній ціні. Весь можна розділити на наступні кроки:"
        steps={[
          {
            heading: "Зустріч",
            description:
              "Ви приїжджаєте в наш офіс і ми оцінюємо ваше авто згідно його стану та ринкової ціни на схожі автомобілі",
          },
          {
            heading: "Переоформлення документів",
            description:
              "Ви оформляєте договір купівлі-продажу вашого автомобіля, або доручення у нотаріуса",
          },
          {
            heading: "Оплата",
            description:
              "Ви повертаєтесь від нотаріуса і ми виплачуємо вам кошти за автомобіль",
          },
        ]}
      />
      <FAQ
        questions={[
          {
            question: "Чим це краще ніж продавати автомобіль самостійно?",
            answer:
              "Вам не потрібно витрачати час та зусилля на продаж вашого авто, тому що ми це зробимо за вас. Ви збережете кошти на платних оголошеннях та рекламі, а також час на зустрічях та розмовах з потенційними покупцями, поїздках на перевірках автомобіля на СТО і тестдрайвах.",
          },
          {
            question: "Чому вигідно продавати авто саме у Бернс Авто?",
            answer: "Ми платимо більше і швидше, ніж наші конкуренти.",
          },
          {
            question: "Через скільки часу я отримаю свої кошти?",
            answer:
              "Одразу після укладання договору купівлі-продажу вашого автомобіля, або генерального доручення у нотаріуса.",
          },
          {
            question: "Які автомобілі Бернс Авто викуповує?",
            answer:
              "Будь-які легкові автомобілі та мотоцикли, у будь-якому стані, не старше 1990 року. Транспортні засоби повинні перебувати українському обліку. Мати 'чисту' юридичну історії, що дозволить нам переоформити їх в сервісних центрах ДАІ (без юридичних обтяжень, заборони відчуження, кредитів, пошкоджень/змін номерів кузова чи двигуна, і т.д.).",
          },
          {
            question:
              "В якому стані має бути авто, щоб Бернс Авто був зацікавлений у його купівлі?",
            answer:
              "Нам цікаві авто у будь-якому стані, включаючи биті та не на ходу.",
          },
          {
            question: "Як визначається ціна мого авто?",
            answer:
              "Ціна вашого автомобіля визначається згідно ринкових цін на даний транспортний засіб, його технічного і косметичного стану.",
          },
          {
            question: "Хто займається оформленням документів?",
            answer: "Всі необхідні документи оформляються у нотаріуса.",
          },
          {
            question: "Чи можу я повернути свій автомобіль назад?",
            answer:
              "Після того як ми викупили ваш автомобіль ми починаємо його ремонт і підготовку для подальшого продажу. Ви можете повернути своє авто оплативши послуги, які були здійснені.",
          },
        ]}
      />
    </Page>
  );
}

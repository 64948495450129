import "./Search.scss";

type SearchProps = {
  onFilterChange: {
    (type: string): { (event: any): void };
  };
  years: number[];
  foundCars: number;
};

export function Search(props: SearchProps) {
  return (
    <section className="search">
      <div className="container">
        <form role="search" className="search-block red-bg">
          <div className="row">
            <div className="col-lg-2 pb-3">
              <label htmlFor="amount-2">Пошук</label>
              <input
                type="search"
                className="form-control"
                placeholder="Назва авто...."
                onChange={props.onFilterChange("text")}
              />
            </div>
            <div className="col-lg-3 pb-3">
              <label id="price-range">Ціна</label>
              <div className="range-search">
                <input
                  className="form-control"
                  placeholder="Від"
                  type="number"
                  aria-labelledby="price-range"
                  onChange={props.onFilterChange("priceFrom")}
                />
                <span>-</span>
                <input
                  className="form-control"
                  placeholder="До"
                  type="number"
                  aria-labelledby="price-range"
                  onChange={props.onFilterChange("priceTo")}
                />
              </div>
            </div>
            <div className="col-lg-3 pb-3">
              <label id="year-range">Рік</label>
              <div className="range-search">
                <select
                  className="form-control"
                  placeholder="Від"
                  aria-labelledby="year-range"
                  onChange={props.onFilterChange("yearFrom")}
                >
                  {props.years.map((year) => (
                    <option value={year} key={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <span>-</span>
                <select
                  className="form-control"
                  placeholder="До"
                  aria-labelledby="year-range"
                  onChange={props.onFilterChange("yearTo")}
                >
                  {[...props.years].reverse().map((year) => (
                    <option value={year} key={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="row">
                <div className="col-lg-6 pb-3">
                  <label htmlFor="amount-2">Місто</label>
                  <select
                    onChange={props.onFilterChange("location")}
                    className="form-control"
                    defaultValue=""
                  >
                    <option value="">Всі міста</option>
                    <option value="Львів">Львів</option>
                    <option value="Київ">Київ</option>
                  </select>
                </div>
                <div className="col-lg-6 pb-3">
                  <label htmlFor="amount-2">Показати спочатку</label>
                  <select
                    onChange={props.onFilterChange("sorting")}
                    className="form-control"
                    defaultValue="date-desc"
                  >
                    <option value="date-desc">Щойно додані</option>
                    <option value="price-asc">Дешевші</option>
                    <option value="price-desc">Дорожчі</option>
                    <option value="year-asc">Старіші</option>
                    <option value="year-desc">Новіші</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-white">
              Знайдено <strong>{props.foundCars}</strong> авто.
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

import "./Services.scss";
import { ServicesProps } from "../Model";

export default function Services(props: ServicesProps) {
  return (
    <section className="welcome-block objects-car why-choose page-section-ptb white-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h2>{props.title}</h2>
              <div className="separator"></div>
              <p>{props.description}</p>
            </div>
          </div>
        </div>
        <div className="row">
          {props.services.map((service) => (
            <div className="col-lg-4" key={service.title}>
              <div className="feature-box text-center">
                <a href={service.href}>
                  <i>
                    <svg className={`icon ${service.icon}`}>
                      <use xlinkHref={`#${service.icon}`} />
                    </svg>
                  </i>
                </a>
                <div className="content">
                  <h6>{service.title}</h6>
                  <p>{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

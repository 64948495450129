import { EMAIL, HOLIDAYS, LOCATIONS, SOCIAL_MEDIA } from "../../config";
import "./HeaderContacts.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { trackContact } from "../trackContact";

const socialIcons = {
  faInstagram: faInstagram,
  faFacebook: faFacebook,
};

export default function HeaderContacts() {
  return (
    <div className="topbar">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="topbar-left text-lg-left text-center">
              <ul className="list-inline">
                <li>
                  {" "}
                  <a
                    href={`mailto:${EMAIL}`}
                    onClick={() => trackContact("mail")}
                  >
                    <FontAwesomeIcon icon={faEnvelope} /> {EMAIL}
                  </a>
                </li>
                <li>
                  {" "}
                  <FontAwesomeIcon icon={faClock} />
                  {` Відчинено з ${LOCATIONS[0].startingHour}:00 до ${
                    LOCATIONS[0].closingHour
                  }:00. ${HOLIDAYS.map((day) => `${day} ЗАЧИНЕНО`).join(", ")}`}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="topbar-right text-lg-right text-center">
              <ul className="list-inline">
                <li>
                  <FontAwesomeIcon icon={faPhone} />{" "}
                  <a
                    href={`tel:${LOCATIONS[0].phone}`}
                    onClick={() => trackContact("phone")}
                  >
                    {LOCATIONS[0].phone}
                  </a>
                </li>
                {SOCIAL_MEDIA.map((page) => (
                  <li key={page.name}>
                    <a
                      href={page.link}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => trackContact("social")}
                    >
                      {/* @ts-ignore*/}
                      <FontAwesomeIcon icon={socialIcons[`fa${page.name}`]} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Banner from "../components/banner/Banner";
import Cars from "../components/car/Cars";
import Counters from "../components/counters/Counters";
import Schedule from "../components/schedule/Schedule";
import Services from "../components/services/Services";
import Page from "./Page";
import { LOCATIONS } from "../config";
import { ReactComponent as Icons } from "../images/icons-large.svg";
import { CarPropsType } from "../components/Model";

export const services = {
  title: "Додаткові послуги",
  description:
    "Бернс Авто надає шкидкі і зручні послуги продажу та обміну вживаних авто по найвигідніших цінах на ринку.",
  services: [
    {
      img: "images/Berns_Buys1-min.jpg",
      title: "Автовикуп",
      icon: "buyover",
      href: "/we-buy-your-car",
      description: "Купимо ваше авто швидко за хорошу ціну",
    },
    {
      img: "images/Berns_Obmin1-min.jpg",
      icon: "car-exchange-2",
      title: "Обмін авто",
      href: "/car-exchange",
      description: <span>Обміняємо ваш автомобіля на кращий</span>,
    },
    {
      img: "images/Berns_Zakaz1-min.jpg",
      title: "Авто під замовлення",
      icon: "custom-car",
      href: "/car-order",
      description: "Знайдемо автомобіль в Україні або закородоном",
    },
    // {
    //   img: "images/Berns_Leasing1-min.jpg",
    //   title: "Авто в лізинг або кредит",
    //   icon: "financing",
    //   href: "/car-financing.html",
    //   description: "Оплачуєте авто частинами",
    // },
  ],
};

export default function Home() {
  const [cars, setCars] = useState<CarPropsType[]>([]);
  useEffect(() => {
    document.title = "Бернс Авто - Продаж вживаних авто";

    window.gtag("event", "page_view", {
      page_title: "Головна",
      page_path: "/",
    });
    fetch("/data.json").then(async (result) => {
      setCars(await result.json());
    });
  }, []);

  return (
    <Page>
      <Banner {...services} />
      <Cars cars={cars} />
      <Services {...services} />
      <Counters
        counters={[
          {
            name: "Авто в наявності",
            value: cars.length,
            icon: "car-1",
          },
          {
            name: "Авто продано",
            value: 1456,
            icon: "cars-sold-white",
          },
          {
            name: "Відгуків",
            value: 45,
            icon: "comment-7",
          },
          {
            name: "Міст",
            value: LOCATIONS.length,
            icon: "location",
          },
        ]}
      />
      <Schedule />
      <Icons />
      <iframe
        title="maps"
        width="600"
        height="450"
        frameBorder="0"
        style={{ border: 0, width: "100%", background: "grey" }}
        src="https://www.google.com/maps/embed/v1/place?q=Бернс Авто&key=AIzaSyCfzEicwkC6UIlZo-UH11ziIb1eOzv-_y4"
        allowFullScreen
      ></iframe>
    </Page>
  );
}

export const HOLIDAYS: string[] = [];
export const DAYS = [
  "Понеділок",
  "Вівторок",
  "Середа",
  "Четвер",
  "П'ятниця",
  "Субота",
  "Неділя",
];
export const EMAIL = "berns-auto@gmail.com";
export const LOCATIONS = [
  {
    contact: "Андрій",
    location: "Львів",
    phone: "+38 (097) 31-729-79",
    address: "Вулиця Богдана Хмельницького 192 б, Львов, 79000",
    startingHour: "10",
    closingHour: "18",
  },
  {
    location: "Київ",
    contact: "Максим",
    phone: "+38 (067) 353-85-47",
    address: "Велика Окружна дорога 4, Київ, 08130",
    startingHour: "10",
    closingHour: "18",
  },
];
export const SOCIAL_MEDIA = [
  {
    name: "Facebook",
    link: "https://www.facebook.com/bernsauto",
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/bernsauto",
  },
];

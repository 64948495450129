import React from "react";
import ReactDOM from "react-dom";
// import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./common.scss";

import Home, { services } from "./page/Home";
import WeBuyYourCar from "./page/WeBuyYourCar";
// import Financing from "./page/Financing";
import CarOrdering from "./page/CarOrdering";
import CarExchange from "./page/CarExchange";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path={services.services[0].href}>
          <WeBuyYourCar />
        </Route>
        <Route path={services.services[1].href}>
          <CarExchange />
        </Route>
        <Route path={services.services[2].href}>
          <CarOrdering />
        </Route>
        {/* <Route path={services.services[3].href}>
          <Financing />
        </Route> */}
        <Route path="/" exact>
          <Home />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

const rootElement = document.getElementById("root");
if (rootElement?.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
}

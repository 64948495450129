import React, { useEffect } from "react";
import { FAQ } from "../components/faq/FAQ";
import { ServiceSteps } from "../components/services/ServiceSteps";
import { services } from "./Home";
import Page from "./Page";

export default function CarExchange() {
  useEffect(() => {
    document.title = "Бернс Авто - Обмін авто";
    window.gtag("event", "page_view", {
      page_title: "Обмін авто",
      page_path: services.services[1].href,
    });
  }, []);
  return (
    <Page>
      <ServiceSteps
        serviceName="Обмін авто"
        serviceDescription="Обмінюємо ваш старий автомобіль на наш з доплатою. Весь процес займе кілька годин і його можна розділити на наступні кроки:"
        steps={[
          {
            heading: "Зустріч",
            description:
              "Ви приїжджаєте в наш офіс і ми оцінюємо ваше авто згідно його технічого і візуального стану, та ринкової ціни на схожі автобілі",
          },
          {
            heading: "Вибір авто",
            description:
              "Ви обираєте авто з наявних пропозицій, оглядаєте його на нашому СТО, та проводите тестдрайв",
          },
          {
            heading: "Оформлення документів",
            description:
              "Бернс Авто оформлює договір купівлі-продажу на вибраний вами автомобіль. Ви в свою чергу оформляєте генеральне доручення на ваш автомобіль у нотаріуса",
          },
          {
            heading: "Фініш",
            description:
              "Ви повертаєтесь від нотаріуса, обмінюєтесь з Бернс Авто документами та автобілями, та сплачуєте різницю вартості",
          },
        ]}
      />
      <FAQ
        questions={[
          {
            question:
              "Чим це краще ніж продати свій автомобіль і купити новий самостійно?",
            answer:
              "Вам не потрібно витрачати час та зусилля на продаж вашого авто, бо ми це зробимо за вас. Ви збережете кошти на рекламі, а також час на розмовах і зустрічах з потенційними покупцями. Ви не залишаєтесь без транспортного засобу.",
          },
          {
            question: "Чому обмінювати авто саме у Бернс Авто?",
            answer:
              "Ми одні з небагатьгох компанії які мають великий вибір авто та надають цю послугу. Також ми оцінюємо ваше авто дорожче ніж конкуренти.",
          },
          {
            question: "Як дового відбувається процес обміну?",
            answer:
              "Процес оцінки, та оформлення займає кілька годин. Зазвичай ми віддаємо авто через кілька годин після початкової зустрічі.",
          },
          {
            question: "Які автомобілі Бернс Авто бере на обмін",
            answer:
              "Будь-які легкові автомобілі та мотоцикли, у будь-якому стані, не старше 1990 року. Транспортні засоби повинні перебувати українському обліку. Мати 'чисту' юридичну історії, що дозволить нам переоформити їх в сервісних центрах ДАІ (без юридичних обтяжень, заборони відчуження, кредитів, пошкоджень/змін номерів кузова чи двигуна, і т.д.).",
          },
          {
            question: "Чи можу я взяти дорожчий автомобіль?",
            answer: "Так.",
          },
          {
            question: "Чи можу я взяти дешевший автомобіль?",
            answer:
              "Можливо, це залежить від вашого авто. Зателефонуйте нам, і ми з радістю обговоримо деталі.",
          },
          {
            question:
              "В якому стані має бути авто щоб Бернс Авто був зацікавлений у його обміні?",
            answer:
              "Нам цікаві авто у будь-якому стані, включаючи биті та не на ходу.",
          },
          {
            question: "Як визначається ціна мого авто?",
            answer:
              "Ціна вашого автомобіля визначається згідно ринкових цін на даний транспортний засіб, його технічного і косметичного стану.",
          },
          {
            question: "Хто займається оформленням документів?",
            answer: "Всі необхідні документи оформляються у нотаріуса.",
          },
          {
            question: "Чи можу я повернути свій автомобіль назад?",
            answer:
              "Після того як ми викупили ваш автомобіль ми починаємо його ремонт і підготовку для подальшого продажу. Ви можете повернути своє авто оплативши послуги, які були здійснені.",
          },
        ]}
      />
    </Page>
  );
}

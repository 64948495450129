import { ServicesProps } from "../Model";
import classes from "./Banner.module.scss";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

export default function Banner(props: ServicesProps) {
  return (
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={33}
      totalSlides={3}
      infinite={true}
      className="carousel"
    >
      <Slider>
        {props.services.map((slide, index) => (
          <Slide index={index}>
            <div
              key={slide.img}
              className={classes.carouselItem}
              data-slide={index}
            >
              <img src={slide.img} alt="First slide" height="480" />
              <a href={slide.href}>
                <div className={classes.carouselCaption}>
                  <h3 className="mb-2">{slide.title}</h3>
                  <div>{slide.description}</div>
                </div>
              </a>
            </div>
          </Slide>
        ))}
      </Slider>
      <ButtonBack className={classes.leftButton}> </ButtonBack>
      <ButtonNext className={classes.rightButton}> </ButtonNext>
    </CarouselProvider>
  );
}

import { Link, NavLink } from "react-router-dom";
import { services } from "../../page/Home";
import "./MainMenu.scss";
export default function MainMenu() {
  return (
    <div id="header" className="topbar-dark">
      <div className="menu">
        <div className="container">
          <nav className="mega-menu">
            <section className="menu-list-items">
              <div className="row">
                <div className="col-md-12">
                  <ul className="menu-logo  d-none d-sm-block">
                    <li>
                      <Link to="/">Бернс Авто</Link>
                    </li>
                  </ul>
                  <ul className="menu-links">
                    <li>
                      <NavLink to="/" exact>
                        Головна сторінка
                      </NavLink>
                    </li>
                    {services.services.map((service) => {
                      return (
                        <li key={service.href}>
                          <NavLink to={service.href}>{service.title}</NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </section>
          </nav>
        </div>
      </div>
    </div>
  );
}

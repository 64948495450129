import React, { useState } from "react";
import Schedule from "../schedule/Schedule";
import "./FAQ.scss";
type FaqProps = {
  questions: { question: string; answer: React.ReactNode | string }[];
};
export function FAQ(props: FaqProps) {
  const [active, setActive] = useState<string[]>([]);
  function isActive(item: { question: string }) {
    console.log(active);
    return active.indexOf(item.question) !== -1;
  }
  return (
    <div className="container  pt-5">
      <div className="row">
        <div className="col-md-12">
          <div className="section-title">
            <h2>Часто задавані питання</h2>
            <div className="separator"></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-5 ">
          <div className="accordion">
            {props.questions.map((item) => (
              <div
                key={item.question}
                className={isActive(item) ? "active" : ""}
              >
                <button
                  className="accordion-title"
                  onClick={() => {
                    const newActive = [
                      ...active.filter((a) => a !== item.question),
                    ];
                    if (!isActive(item)) {
                      newActive.push(item.question);
                    }

                    setActive(newActive);
                  }}
                >
                  {item.question}
                </button>
                <div className="accordion-content">{item.answer}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Schedule />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Car from "./Car";
import { CarPropsType } from "../Model";
import { Search } from "../search/Search";
import { FixedSizeList } from "react-window";
import debounce from "lodash/debounce";
function compare(type: string, a: CarPropsType, b: CarPropsType): number {
  switch (type) {
    case "year":
      return a.year - b.year;
    case "price":
      return a.price - b.price;
    case "date":
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    default:
      console.error("unknown sorting type:", type);
      return 0;
  }
}

function filter(cars: CarPropsType[], filters: Filters) {
  return cars
    .filter((car) => {
      if (
        (filters.priceTo && car.price > filters.priceTo) ||
        car.price < filters.priceFrom ||
        car.year > filters.yearTo ||
        car.year < filters.yearFrom ||
        (filters.location && car.location !== filters.location) ||
        `${car.name} ${car.description} ${car.year}`
          .toLowerCase()
          .indexOf(filters.text.toLowerCase()) === -1
      ) {
        return false;
      }
      return true;
    })
    .sort((a, b) => {
      const [type, direction] = filters.sorting.split("-");
      return compare(type, a, b) * (direction === "desc" ? -1 : 1);
    });
}

type Filters = {
  yearFrom: number;
  yearTo: number;
  priceFrom: number;
  priceTo: number;
  text: string;
  sorting: string;
  location: string;
};

type CarsProps = {
  cars: CarPropsType[];
};
const debouncedEvent = debounce(window.gtag, 5000);
function trackSearch(type: string) {
  const logger = type === "text" ? debouncedEvent : window.gtag;
  logger("event", "search", {
    value: type,
  });
}
export default function Cars(props: CarsProps) {
  const [filters, setFilters] = useState<Filters>({
    yearFrom: 0,
    yearTo: Infinity,
    priceFrom: 0,
    priceTo: Infinity,
    text: "",
    sorting: "date-desc",
    location: "",
  });
  const filteredCars = filter(props.cars, filters);

  const loading = <p className="text-center pt-5 pb-5">Завантаження...</p>;
  const empty = (
    <p className="text-center pt-5 pb-5">
      Нічого не знайдено, спробуйте змінити параменти пошуку.
    </p>
  );
  const listRef = React.createRef<FixedSizeList>();
  useEffect(() => {
    const handler = () => {
      if (listRef) {
        listRef.current?.scrollTo(window.scrollY);
      }
    };
    window.addEventListener("scroll", handler);
    return () => {
      window.removeEventListener("scroll", handler);
    };
  }, [listRef]);

  const carListInternal = (
    <FixedSizeList
      height={150}
      itemCount={filteredCars.length}
      itemSize={window.innerWidth > 992 ? 317 : 529}
      width={window.innerWidth > 992 ? "100%" : 330}
      style={{ height: "100% important", margin: "0 auto" }}
      ref={listRef}
    >
      {({ index, style }) => (
        <div key={filteredCars[index].href} style={style}>
          <Car {...filteredCars[index]} />
        </div>
      )}
    </FixedSizeList>
  );
  const carList = filteredCars.length > 0 ? carListInternal : empty;
  return (
    <>
      <Search
        years={[...new Set(props.cars.map(({ year }) => year).sort()).values()]}
        foundCars={filteredCars.length}
        onFilterChange={(type) => {
          return (event) => {
            trackSearch(type);
            const value =
              type === "text" || type === "sorting" || type === "location"
                ? event.target.value
                : Number(event.target.value);
            setFilters({ ...filters, [type]: value });
          };
        }}
      />
      <section className="product-listing">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {props.cars.length > 0 ? carList : loading}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import "./Footer.scss";
import SignUpForm from "./SingUpForm";
export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row pt-5">
          <div className="col-lg-6 col-md-6">
            <div className="about-content">
              <h6 className="text-white">
                Автосалон Бернс Авто - продаж вживаних автомобілів
              </h6>
              <p className="text-white">
                Продаж автомобілів, терміновий автовикуп, послуги автосервісу,
                мийка, передпродажна підготовка (полірування, хімчистка і т.д.),
                комісійний продаж, пошук авто під замовлення, пригін авто з
                закордону, розмитення авто, обмін, кредитування та лізинг.
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <SignUpForm />
          </div>
        </div>
        <hr />
        <div className="copyright">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="text-lg-left">
                <p>©Copyright Бернс Авто 2020</p>
                <div>
                  Icons made by{" "}
                  <a
                    href="https://www.flaticon.com/authors/smashicons"
                    title="Smashicons"
                  >
                    Smashicons
                  </a>
                  ,
                  <a
                    href="https://www.flaticon.com/authors/vitaly-gorbachev"
                    title="Vitaly Gorbachev"
                  >
                    Vitaly Gorbachev
                  </a>
                  ,{" "}
                  <a
                    href="https://www.flaticon.com/authors/monkik"
                    title="monkik"
                  >
                    monkik
                  </a>
                  ,{" "}
                  <a
                    href="https://www.flaticon.com/authors/surang"
                    title="surang"
                  >
                    surang
                  </a>{" "}
                  from{" "}
                  <a href="https://www.flaticon.com/" title="Flaticon">
                    www.flaticon.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

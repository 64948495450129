import classes from "./Counters.module.scss";
type CounterProps = {
  counters: {
    name: string;
    value: number;
    icon: string;
  }[];
};
export default function Counters(props: CounterProps) {
  return (
    <section className={classes.bg}>
      <div className="container">
        <div className="row">
          {props.counters.map((counter) => (
            <div
              className={`${classes.item} text-left col-6 col-md-${Math.max(
                3,
                12 / props.counters.length
              )}`}
              key={counter.name}
            >
              <div className={classes.counterBlock}>
                <div className={classes.separator}></div>
                <div className={classes.info}>
                  <h6 className="text-white">{counter.name}</h6>
                  <i className={`${classes.icon} ${counter.icon}`}>
                    <svg className={`icon ${counter.icon}`}>
                      <use xlinkHref={`#${counter.icon}`} />
                    </svg>
                  </i>
                  <b className="text-white">{counter.value}</b>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
